.dropdown-toggle {
  width: 100%;
  border: 0.5px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0px 0px 0px 8px;
  color: #545454;
  margin: 0.5rem 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.dropdown-container {
  padding: 0.5rem 1rem;
  border: 0.5px solid #D9D9D9;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 6px 0px;
}

.form-check {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .125rem 0;
  border-radius: 4px;
}

.form-check-input {
  border: 1.5px solid #35A600;
  border-radius: 3px;
  height: 1.3rem;
  width: 1.3rem;
  outline: none;
  cursor: pointer;
}

.form-check:hover {
  background-color: #EAF1F8;
}

.form-check-select-all {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.multi-select-select-all-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  gap: 0.5rem;
  transition: all 0.3s ease 0s;
}

.button1 {
  height: 40px;
  font-size: 16px;
  color: #FFFFFF;
  background: #044089;
  border: 1px solid #044089;
  /* box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.4); */
  border-radius: 6px;
  padding: 0.25rem;
}

.button2 {
  font-size: 14px;
  color: #FFFFFF;
  background: #044089;
  border: 1px solid #044089;
  border-radius: 6px;
}

/* .button2:hover {
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.4);
  color: #E1E1E1;
} */

/* .button1:hover {
  transform: translateY(-2px);
} */

.button1-outline {
  font-size: 12px;
  line-height: 20px;
  color: #044089;
  background: white;
  border: 1px solid #044089;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 0.25rem;
}

input~.dot {
  background-color: #545454;
}

.toggle-container {
  border: 0.781301px solid #545454;
  border-radius: 7.81301px;
}

input:checked~.dot {
  transform: translateX(100%);
  background-color: #044089;
}

.loader {
  border-radius: 9999px;
  border-bottom-width: 2px;
  border-color: rgb(243, 244, 246);
  margin: auto;
}

.loader-button1 {
  height: 20px;
  width: 20px;
}

.inputCommon {
  position: relative;
  color: #545454;
  padding: 7px;
  border: 1px solid #E1E1E1;
  border-radius: 4px;
  padding-left: 0.5rem;
  font-size: 0.8rem;
  padding-right: 1rem;
}

/* inputs */

.input1 {
  padding-left: 36px;
  max-height: 90%;
}

.input1:focus {
  background-color: #E0EEFF;
}

.input1SearchIcon {
  position: absolute;
  left: 6px;
  z-index: 10;
}

.inputCommonCrossIcon {
  position: absolute;
  right: 4px;
  z-index: 10;
  cursor: pointer;
}

.inputCommon::placeholder {
  color: #BCBCBC;
}

.phNoInput::placeholder {
  color: #B6B6B6;
}

.grayPlaceholder::placeholder {
  color: #999999;
}

.blueFocusOutline:focus {
  border: 2px solid #174184;
  border-radius: 4px 0 0 4px;
  outline: none;
}

/* inputs */

.registerElement {
  border-bottom: 1px solid #EEEEEE;
  color: #545454;
  border-left: 6px solid white;
  -webkit-transition: background-color 300ms linear, border 200ms linear;
  -moz-transition: background-color 300ms linear, border 200ms linear;
  -o-transition: background-color 300ms linear, border 200ms linear;
  -ms-transition: background-color 300ms linear, border 200ms linear;
  transition: background-color 300ms linear, border 200ms linear;
}

/* .registerElement:hover {
  background-color: #E0EEFF;
  border-left: 6px solid rgba(20, 76, 144, 0.6);
} */

.selectedRegisterElement {
  background-color: #E0EEFF;
  border-left: 6px solid rgba(20, 76, 144, 1);
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}

.businessElement {
  color: #878787;
  background-color: white;
  font-weight: 300;
}

.selectedBusinessElement {
  color: #044089;
  background-color: #E0EEFF;
  font-weight: 600;
}

.child-in-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Column Header Menu */

.columnMenuItem {
  font-weight: 600;
  font-size: 0.9rem;
  color: #545454;
  background-color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

.columnMenuItem:hover {
  color: #044089;
  background-color: #EAF1F8;
}

/* Column Header Menu */

/* Navbar */
.navbar {
  color: rgba(84, 84, 84, 0.5);
  border-bottom: 1px solid #ECF1F7;
}

.navbarActive {
  color: #174184;
  border-bottom: 3px solid #174184;
  border-radius: 2px;
}

/* Navbar */

/* Drop Container */
.drop-container {
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0;
}

/* Drop Container */

/* Animations */

.slide_down {
  animation: slidedown1 0.4s;
}

.slide_down_2 {
  animation: slidedown2 0.4s;
}

.slide_down_50 {
  animation: slidedown50 0.4s;
}

@keyframes slidedown1 {
  0% {
    margin-top: -5%;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slidedown2 {
  0% {
    margin-top: -1%;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slidedown50 {
  0% {
    margin-top: -50%;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Animations */

/* media queries */

/* media queries */