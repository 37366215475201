.date-picker-reminder{
  font-size: 1em !important;
  width: 100%;
  border: 1px solid #A9A9A9;
  border-radius: 0.3rem;
  cursor: pointer;
}

div.reminder-datetime-picker-form, input.reminder-datetime-picker-form, button.reminder-datetime-picker-form{
  color: #545454;
  height: 2.5rem;
  width: 100%;
  background-color: #F5F5F5;
  box-shadow: none;
  border: 1.5px solid white;
  border-radius: 6.49492px;
  font-size: 1rem; 
  padding-left: 1rem;
  -webkit-transition: background-color 300ms linear, border 50ms linear;
  -moz-transition: background-color 300ms linear, border 50ms linear;
  -o-transition: background-color 300ms linear, border 50ms linear;
  -ms-transition: background-color 300ms linear, border 50ms linear;
  transition: background-color 300ms linear, border 50ms linear;
}

reminder-datetime-picker-form:focus, input.reminder-datetime-picker-form:focus, button.reminder-datetime-picker-form:focus{
  border: 0.1rem solid #044089;
  background-color: white;
  outline: none;
}

.date-picker-reminder.active{
  border: 1px solid red;
}

.date-picker-reminder.normal{
  border: 1px solid #A9A9A9;
}
