/* width */
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #fff;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ECECEC;
    border-radius: 100vh;
    border: 3px solid #fff;
  }

  .businesses_scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #fff;
  }

  .rowDetail::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #fff;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #a0aec0;
  }

  ::placeholder {
    color: #a0aec0; 
    opacity: 1; /* Firefox */
  }