.edit-form {
    right: 16.7vw
}

@media only screen and (max-width: 768px) {
    .edit-form {
        right: 0
    }
}

.activeHeaderIcon {
    background: rgba(255, 255, 255, 0.24)
}

.headerIcon:hover {
    background: rgba(255, 255, 255, 0.24)
}