.animation_item_1 {
  animation: roundMotion 14s -10.5s infinite;
}

.animation_item_2 {
  animation: roundMotion 14s -7s infinite;
}

.animation_item_3 {
  animation: roundMotion 14s -3.5s infinite;
}

.animation_item_4 {
  animation: roundMotion 14s infinite;
}

.title_item_1 {
  color: #FEBF21;
  animation: title 14s -10.5s infinite;
}

.title_item_2 {
  color: #2F87E9;
  animation: title 14s -7s infinite;
}

.title_item_3 {
  color: #00CD15;
  animation: title 14s -3.5s infinite;
}

.title_item_4 {
  color: #F64135;
  animation: title 16s infinite;
}

.description_item_1 {
  animation: description 14s -10.5s infinite;
}

.description_item_2 {
  animation: description 14s -7s infinite;
}

.description_item_3 {
  animation: description 14s -3.5s infinite;
}

.description_item_4 {
  animation: description 14s infinite;
}

@keyframes roundMotion {
  0% {
    top: -52px;
    left: 0;
    opacity: .15;
    /* width: 65%;
    height: 90px; */
    transform: scale(0.85);
    /* font-size: 12px */
  }

  26.66% {
    top: calc(33% - 72px);
    left: -30%;
    opacity: .35;
    /* width: 65%;
    height: 90px; */
    transform: scale(0.85);
    /* font-size: 12px */
  }

  53.132% {
    top: calc(66% - 72px);
    left: -40%;
    opacity: 1;
    transform: scale(1);
    /* font-size: 14px */
  }

  80% {
    top: calc(100% - 82px);
    left: -30%;
    opacity: .35;
    transform: scale(0.85);
    /* font-size: 12px */
  }

  81% {
    opacity: 0
  }

  89% {
    opacity: 0
  }

  90% {
    top: -52px;
    left: 0;
    opacity: .15;
    transform: scale(0.85);
  }

  to {
    top: -52px;
    left: 0;
    opacity: .15;
    transform: scale(0.85);
  }
}

@keyframes title {
  0% {
    font-size: 2.5rem
  }

  26.66% {
    font-size: 2.5rem
  }

  53.132% {
    font-size: 4rem
  }

  80% {
    font-size: 2.5rem
  }

  to {
    font-size: 2.5rem
  }
}

@keyframes description {
  0% {
    color: rgba(255, 255, 255, 0.2);
  }

  26.66% {
    color: rgba(255, 255, 255, 0.2);
  }

  53.132% {
    color: #DFDFDF;
  }

  80% {
    color: rgba(255, 255, 255, 0.2);
  }

  to {
    color: rgba(255, 255, 255, 0.2);
  }
}