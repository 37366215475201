@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback');

@tailwind base;
@tailwind components;

// Additional styles
@import 'additional-styles/utility-patterns.scss';
@import 'additional-styles/range-slider.scss';
@import 'additional-styles/toggle-switch.scss';
@import 'additional-styles/flatpickr.scss';
@import 'additional-styles/theme.scss';
@import 'additional-styles/component_classes.css';
@import 'additional-styles/add_new_entry_popup_classes.css';
@import 'additional-styles/threeDotClasses.css';
@import 'additional-styles/buttons_classes.css';

//datatypes
@import 'additional-styles/datatypes/reminder.css';
@import 'additional-styles/datatypes/text.css';
@import 'additional-styles/datatypes/date.css';

@tailwind utilities;