.textAreaDefaultStyle{
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top: 1px solid rgba(152, 171, 201, 0.3);
  border-right: 1px solid rgba(152, 171, 201, 0.3);
  border-left: 1px solid rgba(152, 171, 201, 0.3);
  border-bottom: none;
  resize: none;
}

.no-outline:focus {
  border-top: 1px solid #044089;
  border-left: 1px solid #044089;
  border-right: 1px solid #044089;
  box-shadow: 1px -1px 0px 1px #B3CCEB,-1px -1px 0px 1px #B3CCEB;
}

.addCommentToolsDivDefaultStyling{
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.addCommentToolsDivGreyStyling{
  border-bottom: 1px solid rgba(152, 171, 201, 0.3);
  border-right: 1px solid rgba(152, 171, 201, 0.3);
  border-left: 1px solid rgba(152, 171, 201, 0.3);
}

.addCommentToolsDivFocusStyling{
  border-bottom: 1px solid #044089;
  border-right: 1px solid #044089;
  border-left: 1px solid #044089;
  box-shadow: -1px 2px 1px 1px #B3CCEB,1px 2px 1px 1px #B3CCEB;
}

.addCommentFocus:hover {
  border: 1px solid #044089;
  border-radius: 6px;
}

.commentBeingEditd {
  border: 1px solid #044089;
  border-radius: 6px;
}

.sendButton {
  font-size: 13px; 
  font-weight: 600; 
  height: 85%;
  width: 50%;
  background-color: #035AC2; 
  outline: none !important;
  padding-bottom: 2; 
  padding-top: 2; 
  border-radius: 0.5rem;
}

.sendButton:hover {
  font-size: 12px; 
  font-weight: 200; 
  height: 85%;
  background-color: #044089;
  outline: none !important;
}

.sendBtnImage{
  content:url("../../../../../../../images/whiteSendIcon.png");
  width: 1.1vw; 
  height: 2.1vh;
}

.sendButton:hover .sendBtnImage {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.cancelButton {
  font-size: 12px; 
  font-weight: 200; 
  height: 85%;
  background-color: #fff;
  outline: none !important;
}