.filterIconClass {
  width: 15px;
  height: 15px;
  background: url("../../../../../../images/FilterGrey.png") no-repeat;
  background-size: contain;
}

.filterText {
  color: #545454;
  font-weight: 600;
  font-size: 14px;
}

.filterBtnContainer{
  border-radius: 8px;
  border: 1px solid transparent;
}

.filterBtnContainer:hover {
  border: 1px solid #044089;
  border-radius: 8px;
}

.filterBtnContainer:hover>.filterIconClass {
  background: url("../../../../../../images/FilterBlue.png") no-repeat;
  background-size: contain;
}

.filterBtnContainer:hover>.filterText {
  color: #044089;
}

.filterBtnContainerHover {
  border: 1px solid #044089;
  border-radius: 8px;
}

.filterTextHover {
  font-weight: 600;
  font-size: 14px;
  color: #044089;
}
.filterIconClassHover {
  width: 15px;
  height: 15px;
  background: url("../../../../../../images/FilterBlue.png") no-repeat;
  background-size: contain;
}
