/* Primary Button */
.primary_btn {
  color: #FFF;
  font-size: 0.9rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background: #035AC2;
  border-radius: 8px;
}

.primary_btn:hover {
  background-color: #094FA3;
}

.primary_btn:active {
  background-color: #044089;
}

.primary_btn:focus {
  outline: none;
}

/* Primary Button */

/* Secondary Button */

.secondary_btn {
  color: #0F0F0F;
  font-size: 0.9rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background: #FFFFFF;
  border: 1px solid rgba(15, 15, 15, 0.2);
  border-radius: 8px;
}

.secondary_btn:hover {
  background-color: #EAF1F8;
  border: 1px solid #EAF1F8;
}

.secondary_btn:active {
  color: #044089;
  background-color: #FFF;
  border: 1px solid #044089;
}

.secondary_btn:focus {
  background-color: #EAF1F8;
  border: 1px solid #EAF1F8;
  outline: none;
}

/* Secondary Button */

/* Critical Button */
.critical_btn {
  color: #B32306;
  font-size: 0.9rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: #FDEEEE;
  border: 1px solid #FDEEEE;
  border-radius: 8px;
}

.critical_btn:hover {
  background-color: #FDEEEE;
  border: 1px solid #D81205;
}

.critical_btn:active {
  background-color: #FFF;
  border: 1px solid #D81205;
}

.critical_btn:focus {
  outline: none;
}

/* Critical Button */

/* Tertiary Button */

.tertiary_btn {
  color: #035AC2;
  font-size: 0.9rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: 1px solid transparent;
  border-radius: 8px;
}

.tertiary_btn:hover {
  color: #878787;
}

.tertiary_btn:active {
  color: #094FA3;
  border: 1px solid #094FA3;
}

.tertiary_btn:focus {
  outline: none;
}

/* Tertiary Button */