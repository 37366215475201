.h85p{
  height: 85%;
}

.colName {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-size: 1.1rem;
  font-weight: 400;
  color: #044089;
  margin: 0.5rem 0;
}

.field, button.field, textarea.field, input.field {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 2rem;
  height: 40px;
  width: 100%;
  background-color: #F5F9FF;
  box-shadow: none;
  border: 1px solid rgba(152, 171, 201, 0.3);
  border-radius: 8px;
  font-size: 1.1rem;
  -webkit-transition: background-color 300ms linear, border 50ms linear;
  -moz-transition: background-color 300ms linear, border 50ms linear;
  -o-transition: background-color 300ms linear, border 50ms linear;
  -ms-transition: background-color 300ms linear, border 50ms linear;
  transition: background-color 300ms linear, border 50ms linear;
}

.field:hover, button.field:hover, textarea.field:hover, input.field:hover{
  border: 0.1rem solid #ADBFD5;
}

.field:focus, button.field:focus, textarea.field:focus, input.field:focus{
  border: 0.1rem solid #044089;
  background-color: white;
  outline: none;
}

.field_icon{
  height: 1.4rem;
}

.non_editable{
  color: #545454;
  background-color: #EAF1F8;
}

.field_container{
  position: relative;
  display: flex;
  align-items: center;
}

.drop-down-icon{
  position: absolute;
  right: 0.5rem;
  height: 1.75rem;
  pointer-events: none;
}

.cross_icon{
  position: absolute;
  right: 0.75rem;
  height: 0.75rem;
  cursor: pointer;
}

.list_item{
  display: flex;
  align-items: center;
}

.list_item:hover{
  background-color: #F3F3F3;
}