.sampleTableClass th {
    border: 1px solid #E5E5E5;
    text-align: left;
    font-size: 10px;
    height: 5px;
  }

  .sampleTableClass td {
    border: 1px solid #E5E5E5;
    font-size: 10px;
    background-color: #fff;
    height: 5px;
  }

 .sampleTableClass td:nth-child(1) {
    background-color: rgb(227, 240, 255);
    text-align: center;
  }

  .sampleTableClass tr:nth-child(1) {
    background-color: rgb(227, 240, 255);
  }

  .sampleTableClass td:nth-child(n+2) {
    width: 100px;
  }
  