.textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  font-family: inherit;
  font-size: inherit;
}

.textarea[contenteditable]:empty::before {
  content: "Placeholder still possible";
  color: gray;
}