.sheetItem1 {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	line-height: 20px;
	white-space: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 100%;
}

.sheetItem {
	display: flex;
	min-height: 100%;
	min-width: 100%;
	justify-content: center;
	align-items: center;
	text-align: center;
	line-height: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.sheetItem2 {
	display: flex;
	min-height: 100%;
	min-width: 100%;
	justify-content: center;
	align-items: center;
	text-align: center;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-break: break-all;
}

.columnHeaderItem {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	line-height: 20px;
	white-space: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 100%;
}


.c1wupbe700-canary49 {
	padding: 0 !important;
	border-right: none;
	border-left: 1px solid var(--border-color) !important;
}

.edit_hover_class>div:nth-child(1)>div>.expandIconSpan {
	visibility: hidden !important;
}

.edit_hover_class:hover>div:nth-child(1)>div>.expandIconSpan {
	visibility: visible !important;
}

.borderLessCell {
	border: none !important;
}